import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import HeaderSlim from 'components/includes/HeaderSlim';
import Footernew from 'components/includes/Footernew';
import './ProfileConfigurations.css';

function ProfileConfigurations(props) {
  let login = localStorage.getItem('access_token');
  let login2 = localStorage.getItem('id_token');
  let dataid = localStorage.getItem('user_id');
  const param = useParams();

  let la;
  let arrla;
  let arrla1 = '';
  let token = process.env.REACT_APP_PUBLIC_KEY2;
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  };

  if (param.count) {
    la = param.count;
    arrla = la.split('-');
    if (param.count == 'en') {
      arrla = ['en', 'INTERNATIONAL'];
    }
    if (param.count == 'es') {
      arrla = ['es', 'INTERNATIONAL'];
    }
    if (arrla[1]) {
      arrla[1] = arrla[1].toUpperCase();
      arrla1 = arrla[0] + '-' + arrla[1];
      if (arrla[1] == 'INTERNATIONAL') {
        arrla1 = arrla[0];
      }
    }
  } else {
    arrla1 = 'en';
  }
  let [datafetched, setdatafetched] = useState({ data: [] });
  let [shopdata, setshopdata] = useState();
  let id = props.shopid;
  if (!id) {
    id = 'user_accounts';
  }

  let para = useParams();

  useEffect(() => {
    let user_id = localStorage.getItem('user_id');
    if (user_id) {
      fetch(
        'https://strapi-qrailing.azurewebsites.net/api/configurations?filters[UserId][$eq]=' +
          user_id.replace(/"/g, ''),
        requestOptions,
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          setdatafetched(data);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  }, []);
  const [ConfigData, setConfigData] = useState([]);

  function getConfigurationData(data, use) {
    let arr = data.split('::');

    if (use == 'form') {
      return arr[2];
    }
    if (use == 'length') {
      if (arr[2] == 'i') {
        let returnnumber = parseInt(arr[7]);
        return returnnumber;
      }
      if (arr[2] == 'l') {
        let returnnumber = parseInt(arr[7]) + parseInt(arr[8]);
        return returnnumber;
      }
      if (arr[2] == 'u') {
        let returnnumber = parseInt(arr[7]) + parseInt(arr[8]) + parseInt(arr[9]);
        return returnnumber;
      }
    }

    if (use == 'type') {
      if (arr[1] == 'baluster') {
        return 'Easy Alu Glass';
      }
      if (arr[1] == 'picket') {
        return 'Easy Alu Picket';
      }
      if (arr[1] == 'glass') {
        return 'Easy Glass Smart';
      }
      if (arr[1] == 'frenchbalcony') {
        return 'Frech Balcony';
      }
      if (arr[1] == 'baseclamps') {
        return 'MOD 6000/6400';
      }
      if (arr[1] == 'qline') {
        return 'Q-line/Square Line';
      }
    }
  }

  return (
    <>
      <div className="ProfileConfigurations-wrap">
        <div className="ProfileConfigurations-inner">
          {datafetched.data.length > 0 && (
            <>
              <div className="ProfileConfigurations-table">
                <table>
                  <tr className="ProfileConfigurations-table-head">
                    <td>
                      <b>Configuration</b>
                    </td>
                    <td>
                      <b>Configuration Code</b>
                    </td>
                    <td>
                      <b>Date</b>
                    </td>
                    <td>
                      <b>Configuration-Link</b>
                    </td>
                    <td>
                      <b>Status</b>
                    </td>
                  </tr>
                  {datafetched.data.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <p>
                            {getConfigurationData(item.attributes.ConfiguratorData, 'type')}-
                            {getConfigurationData(item.attributes.ConfiguratorData, 'form')}-
                            {getConfigurationData(item.attributes.ConfiguratorData, 'length')} mm
                          </p>
                        </td>
                        <td>{item.attributes.Slug}</td>
                        <td>{item.attributes.DateTime}</td>
                        <td>
                          <a target="_blank" href={'/de-de/gelaender/konfigurator/' + item.attributes.Slug}>
                            Open Configuration Link
                          </a>
                        </td>
                        <td>{item.attributes.Slug}</td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default ProfileConfigurations;
