import Footernew from 'components/includes/Footernew';
import HeaderSlim from 'components/includes/HeaderSlim';
import React, { useEffect, useState } from 'react';
import ProfileAddressBook from './ProfileAddressBook';
import { Link, useParams } from 'react-router-dom';
import ProfileUserData from './ProfileUserData';
import './ProfilePage.css';
import ProfileOrders from './ProfileOrders';
import ProfileInvoices from './ProfileInvoices';
import ProfileConfigurations from './ProfileConfigurations';

function ProfilePage(props) {
  let para = useParams();
  let login = localStorage.getItem('access_token');
  let login2 = localStorage.getItem('id_token');
  const [AccData, setAccData] = useState({});
  const [BtnState, setBtnState] = useState('Profile');

  if (!login) {
    window.location.href = '/de-de/';
  }
  useEffect(() => {
    let impersonate_id = localStorage.getItem('impersonate_id');
    if (!impersonate_id) {
      fetch('http://localhost:3004/auth/profiledata', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${login}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          console.log(data);
          let placeid = JSON.stringify(data.userdata.sub).replace('"', '');
          placeid = placeid.replace("'", '');
          localStorage.setItem('user_id', JSON.stringify(data.userdata.sub).replace('"', ''));
          setAccData(data);
          localStorage.setItem('UserObject', JSON.stringify(data));
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    } else {
      fetch('http://localhost:3004/auth/profiledata', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${login}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          console.log(data);
          setAccData(data);

          localStorage.setItem('UserObject', JSON.stringify(data));
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  }, []);

  const checkTokenExpiration = () => {
    const expireTime = localStorage.getItem('expire');
    console.log('Checkin');
    console.log(expireTime);
    let now = new Date().getTime();
    console.log(now);
    if (expireTime && now > expireTime) {
      console.log('Token expired');
      localStorage.removeItem('access_token');
      localStorage.removeItem('expire');
      localStorage.removeItem('id_token');
      localStorage.removeItem('user_id');
      window.location.href = 'http://localhost:3000/de-de/';
    }
  };
  useEffect(() => {
    checkTokenExpiration();
  }, []);

  console.log(props);

  const changepw = () => {
    fetch('http://localhost:3004/auth/forgotpassword', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userId: localStorage.getItem('access_token'),
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        console.log(response.body);
      })

      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const hasKeys = AccData && Object.keys(AccData).length > 0;
  return (
    <>
      {AccData && hasKeys && (
        <>
          {localStorage.getItem('impersonate_id') && (
            <div className="ProfilePage-impersonate">
              <h6>
                IMPERSONATE:{' '}
                <span>
                  {localStorage.getItem('impersonate_id')}{' '}
                  <Link to="/de-de/admin/">
                    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" fill="#fff" viewBox="0 0 256 256">
                      <path d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z"></path>
                    </svg>
                  </Link>
                </span>
              </h6>
            </div>
          )}
          <HeaderSlim></HeaderSlim>
          <div className="ProfilePage-wrap">
            <div className="ProfilePage-headline">
              <div>
                <h3>Profile</h3>
                <p>Your personal profile page</p>
                <br />
              </div>
              <div className="ProfilePage-headline-logout">
                {!localStorage.getItem('impersonate_id') && <img src={AccData.userdata.picture} width={50} />}
                {/*<button
                  onClick={() => {
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('id_token');
                    localStorage.removeItem('UserObject');
                    window.location.replace('/de-de/');
                  }}
                >
                  Logout
                </button>
                <button
                  onClick={() => {
                    changepw();
                  }}
                >
                  Change Password
                </button>*/}
              </div>
            </div>
            <div className="ProfilePage-Navigation">
              <Link to="/de-de/profile/" className={!props.text && 'activeProfileBtn'}>
                Profile
              </Link>
              <Link className={props.text && props.text == 'orders' && 'activeProfileBtn'} to="/de-de/profile/orders/">
                Orders
              </Link>
              <Link
                className={props.text && props.text == 'invoices' && 'activeProfileBtn'}
                to="/de-de/profile/invoices/"
              >
                Invoices
              </Link>

              <Link
                className={props.text && props.text == 'configurations' && 'activeProfileBtn'}
                to="/de-de/profile/configurations/"
              >
                Configurations
              </Link>
              <button
                onClick={() => {
                  localStorage.removeItem('access_token');
                  localStorage.removeItem('id_token');
                  localStorage.removeItem('expire');

                  window.location.href = '/de-de/';
                }}
              >
                Logout
              </button>
            </div>
            <div className="ProfilePage-inner">
              {BtnState == 'Profilee' && AccData && (
                <>
                  <div className="ProfilePage-Auth0-Data">
                    <h3>AUTH0-Data</h3>

                    <p>
                      <span>EMAIL:</span>
                      {AccData.userdata.email}
                    </p>
                    <p>
                      <span>NICKNAME:</span>
                      {AccData.userdata.nickname}
                    </p>
                  </div>
                </>
              )}

              {BtnState == 'Profile' && !localStorage.getItem('impersonate_id') && (
                <div className="ProfilePage-Token">
                  <p>
                    <span>AUTH_TOKEN:</span>
                    {login}
                  </p>
                  <br />
                  <p>
                    <span>ID_TOKEN:</span>
                    {login2}
                  </p>
                  <br />
                </div>
              )}
            </div>
          </div>

          {props.text != 'orders' && props.text != 'invoices' && props.text != 'configurations' && (
            <>
              <ProfileUserData></ProfileUserData>
              <ProfileAddressBook></ProfileAddressBook>
            </>
          )}
          {props.text == 'orders' && (
            <>
              <ProfileOrders></ProfileOrders>
            </>
          )}

          {props.text == 'invoices' && (
            <>
              <ProfileInvoices></ProfileInvoices>
            </>
          )}

          {props.text == 'configurations' && (
            <>
              <ProfileConfigurations></ProfileConfigurations>
            </>
          )}
          <Footernew></Footernew>
        </>
      )}
    </>
  );
}

export default ProfilePage;
