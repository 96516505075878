import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import HeaderSlim from 'components/includes/HeaderSlim';
import Footernew from 'components/includes/Footernew';
import './AdminCustomerAddresses.css';

function AdminCustomerAddresses(props) {
  let login = localStorage.getItem('access_token');
  let login2 = localStorage.getItem('id_token');
  let [datafetched, setdatafetched] = useState();
  let [shopdata, setshopdata] = useState();
  let id = props.shopid;
  if (!id) {
    id = 'user_accounts';
  }

  let para = useParams();

  useEffect(() => {
    fetch('http://localhost:3004/auth/useraddressesreturnadmin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${login2}`,
      },
      body: JSON.stringify({ access_token: login2, accountId: props.shopid }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setdatafetched(data);
        console.log(data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);

  return (
    <>
      <div className="AdminCustomerAddresses-wrap">
        <div className="AdminCustomerAddresses-inner">
          {datafetched && (
            <>
              {datafetched.billing_addresses && (
                <div className="AdminCustomerAddresses-adresswrap">
                  <div className="AdminCustomerAddresses-Addresses">
                    <div className="AdminCustomerAddresses-BillingAddress">
                      <h3>Billing_addresses</h3>
                      {datafetched.billing_addresses.map((item, index) => {
                        console.log(item);

                        return (
                          <div className="AdminCustomerAddresses-Address" key={index}>
                            <span>
                              <p>Name:</p>
                              <p>{item.name}</p>
                            </span>
                            <span>
                              <p>Street+Number:</p>
                              <p>{item.street_number}</p>
                            </span>
                            <span>
                              <p>Postcode:</p>
                              <p>{item.postcode}</p>
                            </span>
                            <span>
                              <p>City:</p>
                              <p>{item.city}</p>
                            </span>
                            <span>
                              <p>Country:</p>
                              <p>{item.country}</p>
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div className="AdminCustomerAddresses-Addresses">
                    <div className="AdminCustomerAddresses-ShippingAddress">
                      <h3>Shipping_addresses</h3>
                      {datafetched.shipping_addresses.map((item, index) => {
                        console.log(item);

                        return (
                          <div className="AdminCustomerAddresses-Address" key={index}>
                            <span>
                              <p>Name:</p>
                              <p>{item.name}</p>
                            </span>
                            <span>
                              <p>Street+Number:</p>
                              <p>{item.street_number}</p>
                            </span>
                            <span>
                              <p>Postcode:</p>
                              <p>{item.postcode}</p>
                            </span>
                            <span>
                              <p>City:</p>
                              <p>{item.city}</p>
                            </span>
                            <span>
                              <p>Country:</p>
                              <p>{item.country}</p>
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default AdminCustomerAddresses;
