import Footernew from 'components/includes/Footernew';
import HeaderSlim from 'components/includes/HeaderSlim';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import './ProfileAddressBook.css';

function ProfilePage(props) {
  let para = useParams();
  let login = localStorage.getItem('access_token');
  let login2 = localStorage.getItem('id_token');
  const [AddressData, setAddressData] = useState({});

  if (!login) {
    window.location.href = '/de-de/';
  }
  useEffect(() => {
    let impersonate_id = localStorage.getItem('impersonate_id');
    console.log(impersonate_id);
    if (!impersonate_id) {
      console.log('NOT THERE');
      fetch('http://localhost:3004/auth/returncombineduserdata', {
        method: 'Post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${login2}`,
        },
        body: JSON.stringify({ access_token: login }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          console.log(data);
          setAddressData(data);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    } else {
      console.log('THERE');
      fetch('http://localhost:3004/auth/returncombineduserdata', {
        method: 'Post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${login2}`,
        },
        body: JSON.stringify({ userId: impersonate_id }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          console.log(data);
          let setvary = { userdata: data.userdata[0], customer: data.customer };
          setAddressData(setvary);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  }, []);

  const hasKeys = AddressData && Object.keys(AddressData).length > 0;
  return (
    <>
      <div className="ProfileAddressBook-wrap">
        <div className="ProfileAddressBook-inner">
          <br />
          <div className="ProfileAddressBook-Headline">
            <h2>
              <u>WILLKOMMEN KUNDE!</u>
            </h2>

            <br />
          </div>

          {hasKeys && (
            <div className="ProfilePage-Db-Data">
              <h3>USER-Data</h3>
              <p>
                <span>ID:</span>
                {AddressData.userdata.id}
              </p>
              <p>
                <span>MAIL:</span>
                {AddressData.userdata.email}
              </p>
              <p>
                <span>PHONE:</span>
                {AddressData.userdata.phone}
              </p>
              <p>
                <span>COMPANY:</span>
                {AddressData.userdata.company}
              </p>
              <p>
                <span>COUNTRY:</span>
                {AddressData.userdata.country}
              </p>
              <p>
                <span>AUTH0_ID:</span>
                {AddressData.userdata.industry_group}
              </p>
            </div>
          )}
          {hasKeys && (
            <div className="ProfilePage-Db-Data">
              <h3>CUSTOMER-Data</h3>
              <p>
                <span>ID:</span>
                {AddressData.customer.account_id}
              </p>
              <p>
                <span>NAME:</span>
                {AddressData.customer.name}
              </p>
              <p>
                <span>DISCOUNT:</span>
                {AddressData.customer.line_discount}
              </p>
              <p>
                <span>CURRENCY:</span>
                {AddressData.customer.currency}
              </p>
              <p>
                <span>TAX_GROUP:</span>
                {AddressData.customer.tax_group}
              </p>
              <p>
                <span>PAYMENT_TERMS:</span>
                {AddressData.customer.payment_terms}
              </p>
              <p>
                <span>PRICE_GROUP:</span>
                {AddressData.customer.price_group}
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ProfilePage;
