import React, { useEffect, useState } from 'react';
import './AdminCustomerOverview.css';
import { Link } from 'react-router-dom';

function AdminCustomerOverview() {
  let login = localStorage.getItem('access_token');
  let login2 = localStorage.getItem('id_token');
  let [datafetched, setdatafetched] = useState([]);
  let [copydata, setcopydata] = useState([]);
  let [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;

  useEffect(() => {
    fetch('http://localhost:3004/auth/getallusers', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${login2}`,
      },
      body: JSON.stringify({ id_token: login2, upper: 10, lower: 0 }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setdatafetched(data);
        setcopydata(data);
        console.log(data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);

  function filterdata() {
    let filterval = 'account_id';
    let inputval = '11-122795';

    if (document.getElementById('AccountCustomerOverview-FilterSelect')) {
      filterval = document.getElementById('AccountCustomerOverview-FilterSelect').value;
    }
    if (document.getElementById('AccountCustomerOverview-FilterInput')) {
      inputval = document.getElementById('AccountCustomerOverview-FilterInput').value;
    }

    let filterCriteria = filterval;
    let filterValue = inputval;

    console.log(filterCriteria);
    console.log(filterValue);
    let filtervalss = [];
    const filtered = copydata.filter((item) => {
      if (filterCriteria === 'account_id') {
        if (item.account_id.toString().includes(filterValue)) {
          filtervalss = [...filtervalss, item];
        }
      } else if (filterCriteria === 'mail') {
        if (item.email.toString().includes(filterValue)) {
          filtervalss = [...filtervalss, item];
        }
      } else if (filterCriteria === 'role') {
        if (item.role.toString().includes(filterValue)) {
          filtervalss = [...filtervalss, item];
        }
      }
    });
    console.log(filtervalss);
    setdatafetched(filtervalss);
    setCurrentPage(1); // Reset to first page after filtering
  }

  function resetfilter() {
    setdatafetched(copydata);
    setCurrentPage(1); // Reset to first page after resetting filter
  }

  // Calculate the data to display for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = datafetched.slice(startIndex, endIndex);

  // Calculate the total number of pages
  const totalPages = Math.ceil(datafetched.length / itemsPerPage);

  return (
    <div className="AdminCustomerOverview-wrap">
      <div className="AdminCustomerOverview-inner">
        <div>
          <h1>ADMIN AREA : PLEASE LEAVE </h1>

          <div className="AccountCustomerOverview-filterUsers">
            <input id="AccountCustomerOverview-FilterInput" type="text" placeholder="Search customer data..." />

            <select id="AccountCustomerOverview-FilterSelect">
              <option value={'account_id'}> Account_id</option>
              <option value={'mail'}>mail</option>
              <option value={'role'}>role</option>
            </select>
            <button onClick={() => filterdata()}>Filter</button>
            <button id="AdminCustomerOverview-ResetFilter" onClick={() => resetfilter()}>
              Reset Filter
            </button>
          </div>
        </div>
        <table>
          {currentData.length > 0 && (
            <>
              <thead>
                <tr id="AdminCustomerOverview-Table-Head">
                  <td>
                    <b>id</b>
                  </td>
                  <td>
                    <b>email</b>
                  </td>
                  <td>
                    <b>role</b>
                  </td>
                  <td>
                    <b>account_id</b>
                  </td>
                  <td>
                    <b>zum account</b>
                  </td>
                  <td>
                    <b>IMPOSTER! DANGER</b>
                  </td>
                </tr>
              </thead>
              <tbody>
                {currentData.map((item, index) => (
                  <tr key={index}>
                    <td>{item.id}</td>
                    <td>{item.email}</td>
                    <td>{item.role}</td>
                    <td>{item.account_id}</td>
                    <td>
                      <Link className="AdminCustomerOverview-gotouser" to={'/de-de/admin/users/' + item.id}>
                        Zum User
                      </Link>
                    </td>
                    <td>
                      <button
                        className="AdminCustomerOverview-gotouser AdminCustomerOverview-impersonate"
                        onClick={() => {
                          localStorage.setItem('impersonate_id', item.account_id);
                          window.location.replace('/de-de/profile/');
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="#f2f2f2"
                          viewBox="0 0 256 256"
                        >
                          <path d="M236.8,188.09,149.35,36.22h0a24.76,24.76,0,0,0-42.7,0L19.2,188.09a23.51,23.51,0,0,0,0,23.72A24.35,24.35,0,0,0,40.55,224h174.9a24.35,24.35,0,0,0,21.33-12.19A23.51,23.51,0,0,0,236.8,188.09ZM222.93,203.8a8.5,8.5,0,0,1-7.48,4.2H40.55a8.5,8.5,0,0,1-7.48-4.2,7.59,7.59,0,0,1,0-7.72L120.52,44.21a8.75,8.75,0,0,1,15,0l87.45,151.87A7.59,7.59,0,0,1,222.93,203.8ZM120,144V104a8,8,0,0,1,16,0v40a8,8,0,0,1-16,0Zm20,36a12,12,0,1,1-12-12A12,12,0,0,1,140,180Z"></path>
                        </svg>
                        Impersonate
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </>
          )}
        </table>
        <div className="AdminCustomerOverview-pagination">
          <button
            onClick={() => {
              setCurrentPage((prev) => Math.max(prev - 1, 1));
              document.querySelector('.AdminCustomerOverview-wrap').scrollIntoView({ behavior: 'smooth' });
            }}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() => {
              setCurrentPage((prev) => Math.min(prev + 1, totalPages));
              document.querySelector('.AdminCustomerOverview-wrap').scrollIntoView({ behavior: 'smooth' });
            }}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

export default AdminCustomerOverview;
