import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import './ProfileInvoices.css';

function ProfileInvoices(props) {
  let login = localStorage.getItem('access_token');
  let login2 = localStorage.getItem('id_token');
  let [datafetched, setdatafetched] = useState();
  let [shopdata, setshopdata] = useState();
  let id = props.shopid;
  if (!id) {
    id = 'user_accounts';
  }

  let para = useParams();

  useEffect(() => {
    const impersonate_id = localStorage.getItem('impersonate_id');
    fetch('http://localhost:3004/auth/adminreturninvoicesforid', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ access_token: login2, accountId: impersonate_id }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setdatafetched(data);
        console.log(data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);

  return (
    <>
      <div className="AdminCustomerOrders-wrap ProfileInvoices-wrap">
        <div className="AdminCustomerAddresses-inner ProfileInvoices-inner">
          {datafetched && (
            <>
              {datafetched.length > 0 && (
                <div className="AdminCustomerOrders-Orders">
                  <div className="AdminCustomerAddresses-Addresses">
                    <div className="AdminCustomerOrders-Orders">
                      <h3>Invoices</h3>
                      <br />
                      <table className="AdminCustomerOrders-Orderlist">
                        <tr className="AdminCustomerOrders-Tablehead">
                          <td>
                            <span>Invoice_number</span>
                          </td>
                          <td>
                            <span>Invoice_Date</span>
                          </td>
                          <td>
                            <span>Total_charge</span>
                          </td>
                          <td>
                            <span>Currency</span>
                          </td>
                          <td>
                            <span>Order_id</span>
                          </td>
                          <td>
                            <span>Delivery_mode</span>
                          </td>

                          <td>
                            <span>Payment_terms</span>
                          </td>
                        </tr>
                        {datafetched.map((item, index) => {
                          return (
                            <tr key={index} className="AdminCustomerOrders-InnerTable">
                              <td>
                                <span>{item.invoice_number}</span>
                              </td>
                              <td>
                                <span>{item.invoice_date}</span>
                              </td>
                              <td>
                                <span>{item.total_charge}</span>
                              </td>
                              <td>
                                <span>{item.currency}</span>
                              </td>
                              <td>
                                <span>{item.order_id}</span>
                              </td>
                              <td>
                                <span>{item.delivery_mode}</span>
                              </td>

                              <td>
                                <span>{item.payment_terms}</span>
                              </td>
                            </tr>
                          );
                        })}
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default ProfileInvoices;
