import React, { useEffect, useState } from 'react';
import './AdminBackend.css';
import HeaderSlim from 'components/includes/HeaderSlim';
import Footernew from 'components/includes/Footernew';
import AdminCustomerOverview from './AdminCustomerOverview';

function AdminBackend() {
  let login = localStorage.getItem('access_token');
  let login2 = localStorage.getItem('id_token');
  let impersonatereset = localStorage.getItem('impersonate_id');
  const [isAdmin, setIsAdmin] = useState(false);
  if (impersonatereset) {
    localStorage.removeItem('impersonate_id');
  }
  if (!login) {
    window.location.href = '/de-de/';
  }
  useEffect(() => {
    fetch('http://localhost:3004/auth/isadmin', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${login2}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        if (data == 'true' || data == true) {
          console.log('Admin');
          setIsAdmin(true);
        } else {
          window.location.href = '/de-de/';
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);
  return (
    <>
      {isAdmin && <HeaderSlim></HeaderSlim>}

      {isAdmin && <AdminCustomerOverview></AdminCustomerOverview>}
      {isAdmin && <Footernew></Footernew>}
    </>
  );
}

export default AdminBackend;
