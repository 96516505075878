import Footernew from 'components/includes/Footernew';
import HeaderSlim from 'components/includes/HeaderSlim';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import './ProfileAddressBook.css';

function ProfileOrders(props) {
  let para = useParams();
  let login = localStorage.getItem('access_token');
  let login2 = localStorage.getItem('id_token');
  const [AddressData, setAddressData] = useState({});

  if (!login) {
    window.location.href = '/de-de/';
  }
  useEffect(() => {
    let impersonate_id = localStorage.getItem('impersonate_id');
    console.log(impersonate_id);
    if (!impersonate_id) {
      console.log('NOT THERE');
      fetch('http://localhost:3004/auth/adminreturnordersforid', {
        method: 'Post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${login2}`,
        },
        body: JSON.stringify({ access_token: login }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          console.log(data);
          setAddressData(data);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    } else {
      console.log('THERE');
      fetch('http://localhost:3004/auth/adminreturnordersforid', {
        method: 'Post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${login2}`,
        },
        body: JSON.stringify({ accountId: impersonate_id, access_token: login2 }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          console.log(data);
          setAddressData(data);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  }, []);

  const hasKeys = AddressData && Object.keys(AddressData).length > 0;
  return (
    <>
      <div className="ProfileAddressBook-wrap">
        <div className="ProfileAddressBook-inner">
          <div className="ProfileAddressBook-Headline">
            <h2>WILLKOMMEN KUNDE!1</h2>
            <br />
            <p>You can find your ERP addresses here</p>
            <br />
          </div>
          {hasKeys && (
            <table className="AdminCustomerOrders-Orderlist">
              <tr className="AdminCustomerOrders-Tablehead">
                <td>
                  <span>Order_Id</span>
                </td>
                <td>
                  <span>Status</span>
                </td>
                <td>
                  <span>order_amount</span>
                </td>
                <td>
                  <span>Discount code</span>
                </td>
                <td>
                  <span>Discount amount</span>
                </td>

                <td>
                  <span>Shipping_id</span>
                </td>

                <td>
                  <span>Order_reference</span>
                </td>
                <td>
                  <span>Tax_amount</span>
                </td>
              </tr>
              {AddressData.map((item, index) => {
                return (
                  <tr key={index} className="AdminCustomerOrders-InnerTable">
                    <td>
                      <Link to={'/de-de/admin/users/' + props.urlid + '/' + item.order_id}>
                        <span>{item.order_id}</span>
                      </Link>
                    </td>
                    <td>
                      <span>{item.status}</span>
                    </td>
                    <td>
                      <span>{item.total_price}</span>
                    </td>
                    <td>
                      <span>{item.discount_code}</span>
                    </td>
                    <td>
                      <span>{item.discount_amount}</span>
                    </td>

                    <td>
                      <span>{item.shipping_address_id}</span>
                    </td>

                    <td>
                      <span>{item.order_reference}</span>
                    </td>

                    <td>
                      <span>{item.tax_amount}</span>
                    </td>
                  </tr>
                );
              })}
            </table>
          )}
        </div>
      </div>
    </>
  );
}

export default ProfileOrders;
