import React, { useEffect, useRef, useState } from 'react';
import './AdminCustomerDetail.css';
import { Link, useParams } from 'react-router-dom';
import HeaderSlim from 'components/includes/HeaderSlim';
import Footernew from 'components/includes/Footernew';
import AdminCustomerAddresses from './AdminCustomerAddresses';
import AdminCustomerOrders from './AdminCustomerOrders';
import AdminCustomerInvoices from './AdminCustomerInvoices';

function AdminCustomerDetail() {
  let login = localStorage.getItem('access_token');
  let login2 = localStorage.getItem('id_token');
  let [datafetched, setdatafetched] = useState([]);
  let [shopdata, setshopdata] = useState();

  let para = useParams();

  useEffect(() => {
    fetch('http://localhost:3004/auth/getsingleuser', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${login2}`,
      },
      body: JSON.stringify({ access_token: login2, id: para.id }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setdatafetched(data);
        console.log(data);

        if (data.account_id) {
          console.log(datafetched);

          fetch('http://localhost:3004/auth/getsinglecustomer', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${login2}`,
            },
            body: JSON.stringify({ access_token: login2, id: data.account_id }),
          })
            .then((response) => {
              if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
              }
              return response.json();
            })
            .then((data) => {
              console.log('SHOPDATA::::');
              console.log(data);
              console.log('SHOPDATA::::');
              setshopdata(data);
            });
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);

  return (
    <>
      <HeaderSlim></HeaderSlim>
      <div className="AdminCustomerOverview-wrap">
        <div className="AdminCustomerOverview-inner">
          <div className="AdminCustomerDetail-innerData">
            <h1>Customer Detail</h1>
            <div>
              <br />
              <br />
              <Link to="/de-de/admin/" id="BackToAdminAreaLink">
                <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.0306 6.662C10.9609 6.73192 10.8781 6.7874 10.787 6.82525C10.6958 6.8631 10.5981 6.88259 10.4993 6.88259C10.4006 6.88259 10.3029 6.8631 10.2117 6.82525C10.1206 6.7874 10.0378 6.73192 9.9681 6.662L6.74997 3.44387V12.6314C6.74997 12.8303 6.67095 13.021 6.5303 13.1617C6.38965 13.3024 6.19888 13.3814 5.99997 13.3814C5.80106 13.3814 5.61029 13.3024 5.46964 13.1617C5.32899 13.021 5.24997 12.8303 5.24997 12.6314V3.44387L2.0306 6.662C1.8897 6.80289 1.69861 6.88205 1.49935 6.88205C1.30009 6.88205 1.10899 6.80289 0.968098 6.662C0.827202 6.5211 0.748047 6.33001 0.748047 6.13075C0.748047 5.93149 0.827202 5.74039 0.968098 5.5995L5.4681 1.0995C5.53778 1.02958 5.62057 0.9741 5.71173 0.936246C5.8029 0.898392 5.90064 0.878906 5.99935 0.878906C6.09806 0.878906 6.1958 0.898392 6.28696 0.936246C6.37813 0.9741 6.46092 1.02958 6.5306 1.0995L11.0306 5.5995C11.1005 5.66918 11.156 5.75197 11.1938 5.84313C11.2317 5.9343 11.2512 6.03204 11.2512 6.13075C11.2512 6.22946 11.2317 6.3272 11.1938 6.41836C11.156 6.50953 11.1005 6.59232 11.0306 6.662Z"
                    fill="#fff"
                  ></path>
                </svg>{' '}
                Back to Admin Area
              </Link>
              <br />
              <br />
              <br />
              <br />
            </div>
            {datafetched && (
              <>
                <div>
                  <h2>UserData</h2>

                  <div className="AdminCustomerDetail-DataUnit">
                    <div className="AdminCustomerDetail-AvData">
                      <span>id:</span>
                      <p>{datafetched.id}</p>
                    </div>
                    <input type="text" id="UserDataId" />
                  </div>
                  <br />
                  <div className="AdminCustomerDetail-DataUnit">
                    <div className="AdminCustomerDetail-AvData">
                      <span>email:</span>
                      <p>{datafetched.email}</p>
                    </div>
                    <input type="text" id="UserDataEmail" />
                  </div>
                  <br />
                  <div className="AdminCustomerDetail-DataUnit">
                    <div className="AdminCustomerDetail-AvData">
                      <span>Role:</span>
                      <p>{datafetched.role}</p>
                    </div>
                    <input type="text" id="UserDataRole" />
                  </div>
                  <br />
                  {datafetched.account_id && (
                    <>
                      <div className="AdminCustomerDetail-DataUnit">
                        <div className="AdminCustomerDetail-AvData">
                          <span>Account_id:</span>
                          <p>{datafetched.account_id}</p>
                        </div>
                        <input type="text" id="UserDataAccountId" />
                      </div>
                      <br />
                    </>
                  )}
                </div>
              </>
            )}

            {shopdata && (
              <>
                <div>
                  <br />
                  <br />
                  <h2>ShopData</h2>{' '}
                  <div className="AdminCustomerDetail-DataUnit">
                    <div className="AdminCustomerDetail-AvData">
                      <span>id:</span>
                      <p>{shopdata.id}</p>
                    </div>
                    <input type="text" id="CustomerId" />
                  </div>
                  <br />
                  <div className="AdminCustomerDetail-DataUnit">
                    <div className="AdminCustomerDetail-AvData">
                      <span>name:</span>
                      <p>{shopdata.name}</p>
                    </div>
                    <input type="text" id="CustomerName" />
                  </div>
                  <br />
                  <div className="AdminCustomerDetail-DataUnit">
                    <div className="AdminCustomerDetail-AvData">
                      <span>Currency:</span>
                      <p>{shopdata.currency}</p>
                    </div>
                    <input type="text" id="CustomerCurrency" />
                  </div>
                  <br />
                  <div className="AdminCustomerDetail-DataUnit">
                    <div className="AdminCustomerDetail-AvData">
                      <span>Payment terms:</span>
                      <p>{shopdata.payment_terms}</p>
                    </div>
                    <input type="text" id="CustomerPaymentTerms" />
                  </div>
                  <br />
                  <div className="AdminCustomerDetail-DataUnit">
                    <div className="AdminCustomerDetail-AvData">
                      <span>Price group:</span>
                      <p>{shopdata.price_group}</p>
                    </div>
                    <input type="text" id="CustomerPriceGroup" />
                  </div>
                  <br />
                  <div className="AdminCustomerDetail-DataUnit">
                    <div className="AdminCustomerDetail-AvData">
                      <span>Tax group:</span>
                      <p>{shopdata.tax_group}</p>
                    </div>
                    <input type="text" id="CustomerTaxGroup" />
                  </div>
                  <br />
                </div>
              </>
            )}
            <div>
              <button>SAVE DATA ADJUSTMENTS</button>
            </div>
          </div>
        </div>
      </div>
      {shopdata && <AdminCustomerOrders urlid={para.id} shopid={datafetched.account_id}></AdminCustomerOrders>}
      {shopdata && <AdminCustomerInvoices urlid={para.id} shopid={datafetched.account_id}></AdminCustomerInvoices>}
      {shopdata && <AdminCustomerAddresses urlid={para.id} shopid={datafetched.account_id}></AdminCustomerAddresses>}

      <Footernew></Footernew>
    </>
  );
}

export default AdminCustomerDetail;
