import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import './SystemFinder.css';
import LinkData from './Links.json';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

/* eslint-disable no-lone-blocks */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unused-vars */

function SystemFinder(props) {
  let [systemarr, setsystemarr] = useState({ data: [] });
  let [linkarr, setlinkarr] = useState([]);
  const [levelCount, setLevelCount] = useState(0);
  const [optionstring, setOptionString] = useState('');

  let la;
  let arrla;
  let para = useParams();
  let param = useParams();
  let arrla1 = '';
  let [activetoggle, setactivetoggle] = useState('all');
  let [PageTranslations, setPageTranslations] = useState({ data: [] });
  let token = process.env.REACT_APP_PUBLIC_KEY2;
  let [breadcrumbs, setbreadcrumbs] = useState('');
  let [filteredSystemArr] = useState([]);
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  };
  let sizedefinition = 'l';

  if (window.innerWidth < 768) {
    sizedefinition = 's';
  }
  if (window.innerWidth > 1400) {
    sizedefinition = 'xl';
  }
  sizedefinition = 'l';

  let imagesData = {
    // Ganzglas -> Bodenprofil
    'Hohe Last':
      'https://strapiwmc4hstore.blob.core.windows.net/systemfinder/Ganzglas_Bodenprofil_MittelL_' +
      sizedefinition +
      '.webp',
    'Mittlere Last':
      'https://strapiwmc4hstore.blob.core.windows.net/systemfinder/Ganzglas_Bodenprofil_MittelL_' +
      sizedefinition +
      '.webp',
    'Geringe Last':
      'https://strapiwmc4hstore.blob.core.windows.net/systemfinder/Ganzglas_Bodenprofil_MittelL_' +
      sizedefinition +
      '.webp',
    // Ganzglas -> Bodenklemmen
    Absturzsicher:
      'https://strapiwmc4hstore.blob.core.windows.net/systemfinder/Ganzglas_Bodenprofil_MittelL_' +
      sizedefinition +
      '.webp',
    'Nicht Absturzsicher':
      'https://strapiwmc4hstore.blob.core.windows.net/systemfinder/Ganzglas_Bodenprofil_MittelL_' +
      sizedefinition +
      '.webp',
    // Ganzglas -> Adapter -> Punkthalter
    Rund:
      'https://strapiwmc4hstore.blob.core.windows.net/systemfinder/Ganzglas_Bodenprofil_MittelL_' +
      sizedefinition +
      '.webp',
    Eckig:
      'https://strapiwmc4hstore.blob.core.windows.net/systemfinder/Ganzglas_Bodenprofil_MittelL_' +
      sizedefinition +
      '.webp',
    // Ganzglas -> Adapter -> Blockadapter
    'Blockadapter Rund':
      'https://strapiwmc4hstore.blob.core.windows.net/systemfinder/Ganzglas_Bodenprofil_MittelL_' +
      sizedefinition +
      '.webp',
    'Blockadapter Eckig':
      'https://strapiwmc4hstore.blob.core.windows.net/systemfinder/Ganzglas_Bodenprofil_MittelL_' +
      sizedefinition +
      '.webp',
    // Pfostengeländer -> Edelstahl
    Netzfüllung: 'url_to_netzfüllung_image',
    Stabfüllung: 'url_to_stabfüllung_image',
    Glasfüllung: 'url_to_glasfüllung_image',
    // Pfostengeländer -> Aluminium
    'Aluminium Stabfüllung': 'url_to_aluminium_stabfüllung_image',
    'Aluminium Glasfüllung': 'url_to_aluminium_glasfüllung_image',
    // Franz. Balkon
    'Bodentiefes Fenster': 'url_to_bodentiefes_fenster_image',
    'Nicht Bodentief': 'url_to_nicht_bodentief_image',
    // Handlauf -> Holz
    Gebogen: 'url_to_gebogen_image',
    Gerade: 'url_to_gerade_image',
    // Handlauf -> Edelstahl
    'Edelstahl Rund': 'url_to_edelstahl_rund_image',
    'Edelstahl Eckig': 'url_to_edelstahl_eckig_image',
    Ganzglas:
      'https://strapiwmc4hstore.blob.core.windows.net/systemfinder/Ganzglas_Bodenprofil_MittelL_' +
      sizedefinition +
      '.webp',
    Pfostengeländer:
      'https://strapiwmc4hstore.blob.core.windows.net/strapi-uploads/assets/stainless_steel_post_systems_Content_Image_1_a534c6cda3.webp',
    'Franz. Balkon':
      'https://strapiwmc4hstore.blob.core.windows.net/strapi-uploads/assets/Juliet_Balcony_Content_Image_1_15c2a3c96d.webp',
    Handlauf:
      'https://strapiwmc4hstore.blob.core.windows.net/strapi-uploads/assets/Handrail_Content_Image_1_ab137d499e.webp',
    Edelstahl:
      'https://strapiwmc4hstore.blob.core.windows.net/systemfinder/Ganzglas_Bodenprofil_MittelL_' +
      sizedefinition +
      '.webp',
    Aluminium:
      'https://strapiwmc4hstore.blob.core.windows.net/systemfinder/Ganzglas_Bodenprofil_MittelL_' +
      sizedefinition +
      '.webp',
    Holz:
      'https://strapiwmc4hstore.blob.core.windows.net/systemfinder/Ganzglas_Bodenprofil_MittelL_' +
      sizedefinition +
      '.webp',
    Bodenklemmen: 'https://shop.us.q-railing.com/product/image/medium/146200_-11%3D0000000150000c14_0.jpg',
    Adapter: 'https://shop.us.q-railing.com/product/image/medium/140749_-11%3D0000000150000bd8_0.jpg',
    Punkthalter:
      'https://strapiwmc4hstore.blob.core.windows.net/systemfinder/Ganzglas_Bodenprofil_MittelL_' +
      sizedefinition +
      '.webp',
    Blockadapter: 'https://shop.us.q-railing.com/product/image/medium/132500_-11%3D0000000150000bc7_0.jpg',
    Bodenprofil: 'https://shop.us.q-railing.com/product/image/medium/168210_-11%3D0000000150000c7f_0.jpg',
  };
  const imagesDataen = {
    'Hohe Last':
      'https://strapiwmc4hstore.blob.core.windows.net/systemfinder/Ganzglas_Bodenprofil_HoheL_' +
      sizedefinition +
      '.webp',
    'Mittlere Last':
      'https://strapiwmc4hstore.blob.core.windows.net/systemfinder/Ganzglas_Bodenprofil_MittelL_' +
      sizedefinition +
      '.webp',
    'Geringe Last':
      'https://strapiwmc4hstore.blob.core.windows.net/systemfinder/Ganzglas_Bodenprofil_GeringeL_' +
      sizedefinition +
      '.webp',
    Absturzsicher:
      'https://strapiwmc4hstore.blob.core.windows.net/systemfinder/Ganzglas_Bodenklemme_Absturzsicher_' +
      sizedefinition +
      '.webp',
    'Nicht Absturzsicher':
      'https://strapiwmc4hstore.blob.core.windows.net/systemfinder/Ganzglas_Bodenklemme_NAbsturzsicher_' +
      sizedefinition +
      '.webp',
    Rund:
      'https://strapiwmc4hstore.blob.core.windows.net/systemfinder/Handrail_Edelstahl_Rund_' + sizedefinition + '.webp',
    Eckig:
      'https://strapiwmc4hstore.blob.core.windows.net/systemfinder/Handrail_Edelstahl_Eckig_' +
      sizedefinition +
      '.webp',
    'Blockadapter Rund':
      'https://strapiwmc4hstore.blob.core.windows.net/systemfinder/Ganzglas _Adapter_Blockadapter_Rund_' +
      sizedefinition +
      '.webp',
    'Blockadapter Eckig':
      'https://strapiwmc4hstore.blob.core.windows.net/systemfinder/Ganzglas _Adapter_Blockadapter_' +
      sizedefinition +
      '.webp',
    Ganzglasgeländer:
      'https://strapiwmc4hstore.blob.core.windows.net/systemfinder/Ganzglas_' + sizedefinition + '.webp',
    Pfostengeländer:
      'https://strapiwmc4hstore.blob.core.windows.net/systemfinder/Pfostengelander_' + sizedefinition + '.webp',
    'Französischer Balkon':
      'https://strapiwmc4hstore.blob.core.windows.net/systemfinder/FranzBalkon_' + sizedefinition + '.webp',
    'Franz. Balkon':
      'https://strapiwmc4hstore.blob.core.windows.net/systemfinder/FranzBalkon_' + sizedefinition + '.webp',
    Handlauf: 'https://strapiwmc4hstore.blob.core.windows.net/systemfinder/Handrail_' + sizedefinition + '.webp',
    Edelstahl:
      'https://strapiwmc4hstore.blob.core.windows.net/systemfinder/Handrail_Edelstahl_' + sizedefinition + '.webp',
    Aluminium:
      'https://strapiwmc4hstore.blob.core.windows.net/systemfinder/Pfostengelander_Aluminium_' +
      sizedefinition +
      '.webp',
    Holz: 'https://strapiwmc4hstore.blob.core.windows.net/systemfinder/Handrail_Holz_' + sizedefinition + '.webp',
    Bodenklemmen:
      'https://strapiwmc4hstore.blob.core.windows.net/systemfinder/Ganzglas_Bodenklemme_' + sizedefinition + '.webp',
    Adapter: 'https://strapiwmc4hstore.blob.core.windows.net/systemfinder/Ganzglas_Adapter_' + sizedefinition + '.webp',
    Punkthalter:
      'https://strapiwmc4hstore.blob.core.windows.net/systemfinder/Ganzglas _Adapter_Punktadapter_' +
      sizedefinition +
      '.webp',
    Blockadapter:
      'https://strapiwmc4hstore.blob.core.windows.net/systemfinder/Ganzglas _Adapter_Blockadapter_' +
      sizedefinition +
      '.webp',
    'Bodentiefes Fenster':
      'https://strapiwmc4hstore.blob.core.windows.net/systemfinder/FranzBalkon_Btf_' + sizedefinition + '.webp',
    'Kein bodentiefes Fenster':
      'https://strapiwmc4hstore.blob.core.windows.net/systemfinder/FranzBalkon_NBtf_' + sizedefinition + '.webp',
    'Edelstahl Rund':
      'https://strapiwmc4hstore.blob.core.windows.net/systemfinder/Handrail_Edelstahl_Rund_' + sizedefinition + '.webp',
    'Edelstahl Eckig':
      'https://strapiwmc4hstore.blob.core.windows.net/systemfinder/Handrail_Edelstahl_Eckig_' +
      sizedefinition +
      '.webp',
    Gebogen:
      'https://strapiwmc4hstore.blob.core.windows.net/systemfinder/Handrail_Holz_Gebogen_' + sizedefinition + '.webp',
    Gerade:
      'https://strapiwmc4hstore.blob.core.windows.net/systemfinder/Handrail_Holz_Gerade_' + sizedefinition + '.webp',
    Netzfüllung:
      'https://strapiwmc4hstore.blob.core.windows.net/systemfinder/Pfostengelander_Edelstahl_Netzfullung_' +
      sizedefinition +
      '.webp',
    Stabfüllung:
      'https://strapiwmc4hstore.blob.core.windows.net/systemfinder/Pfostengelander_Edelstahl_Stabfullung_' +
      sizedefinition +
      '.webp',
    Glasfüllung:
      'https://strapiwmc4hstore.blob.core.windows.net/systemfinder/Pfostengelander_Edelstahl_Glasfullung_' +
      sizedefinition +
      '.webp',
    'Aluminium Glasfüllung':
      'https://strapiwmc4hstore.blob.core.windows.net/systemfinder/Pfostengelander_Aluminium_Glasfulllung_' +
      sizedefinition +
      '.webp',
    'Aluminium Stabfüllung':
      'https://strapiwmc4hstore.blob.core.windows.net/systemfinder/Pfostengelander_Aluminium_Stabfullung_' +
      sizedefinition +
      '.webp',
    Bodenprofil:
      'https://strapiwmc4hstore.blob.core.windows.net/systemfinder/Ganzglas_Bodenprofil_' + sizedefinition + '.webp',
    'You want a glass railing?':
      'https://shop.us.q-railing.com/product/image/medium/168210_-11%3D0000000150000c7f_0.jpg',
    'Need baseclamps?':
      'https://shop.us.q-railing.com/content/files/theme/home/home_visual_categories/glass_clamps_category.webp',
    'You want a postrailing?':
      'https://shop.us.q-railing.com/content/files/theme/home/home_visual_categories/posts_category.webp',
    'You need a handrail?':
      'https://shop.us.q-railing.com/content/files/theme/home/home_visual_categories/handrails_category.webp',
    'Floorprofile?': 'https://shop.us.q-railing.com/product/image/medium/168210_-11%3D0000000150000c7f_0.jpg',
  };

  imagesData = imagesDataen;
  const [currentLevel, setcurrentLevel] = useState('Art');
  const [imagesrc, setimagesrc] = useState();
  let [flowchartData, setFlowchartData] = useState({
    Art: {
      Ganzglas: {
        Bodenprofil: {
          'Hohe Last': ['easy-glass-strong'],
          'Mittlere Last': ['easy-glass-prime'],
          'Geringe Last': ['easy-glass-smart-plus'],
        },
        Bodenklemmen: {
          Absturzsicher: ['easy-glass-mod-6000-6400'],
          'Nicht Absturzsicher': ['easy-glass-mod-6000-6400', 'easy-glass-mod-6100-6200'],
        },
        Adapter: {
          Punkthalter: {
            Rund: ['easy-glass-mod-0745-0749'],
            Eckig: ['easy-glass-mod-4747'],
          },
          Blockadapter: {
            Rund: ['d-line-blok-easy-glass', 'easy-glass-mod-0760-0766'],
            Eckig: ['easy-glass-mod-0760-0766', 'easy-glass-mod-4762'],
          },
        },
      },
      Pfostengeländer: {
        Edelstahl: {
          Netzfüllung: ['q-line', 'square-line-baluster-railing'],
          Stabfüllung: ['q-line', 'square-line-baluster-railing'],
          Glasfüllung: ['q-line', 'square-line-baluster-railing', 'd-line'],
        },
        Aluminium: {
          Stabfüllung: ['easy-alu'],
          Glasfüllung: ['easy-alu'],
        },
      },
      'Franz. Balkon': {
        'Bodentiefes Fenster': ['easy-glass-view', 'mod-2300', 'easy-glass-mod-6507-6508'],
        'Nicht Bodentief': ['chest-rails'],
      },
      Handlauf: {
        Holz: {
          Gebogen: ['bendywood'],
          Gerade: ['naturail'],
        },
        Edelstahl: {
          Rund: ['q-line', 'd-line-handrail'],
          Eckig: ['square-line-baluster-railing'],
        },
      },
    },
  });

  if (param.count) {
    la = param.count;
    arrla = la.split('-');
    if (param.count == 'en') {
      arrla = ['en', 'INTERNATIONAL'];
    }
    if (param.count == 'es') {
      arrla = ['es', 'INTERNATIONAL'];
    }
    if (arrla[1]) {
      arrla[1] = arrla[1].toUpperCase();
      arrla1 = arrla[0] + '-' + arrla[1];
      if (arrla[1] == 'INTERNATIONAL') {
        arrla1 = arrla[0];
      }
    }
  } else {
    arrla1 = 'en';
  }
  function decidethepaths() {
    if (LinkData) {
      let val = ['en', 'en'];
      if (para.count) {
        if (para.count.match('-')) {
          val = para.count.split('-');
        }
      }
      if (para.count == 'es') {
        val = ['es', 'es'];
      }
      switch (val[0]) {
        case 'de':
          setlinkarr(LinkData.data.de_arr);
          break;
        case 'da':
          setlinkarr(LinkData.data.da_arr);
          break;
        case 'en':
          setlinkarr(LinkData.data.globallangarr);
          break;
        case 'it':
          setlinkarr(LinkData.data.it_arr);
          break;
        case 'es':
          setlinkarr(LinkData.data.es_arr);
          break;
        case 'fi':
          setlinkarr(LinkData.data.fi_arr);
          break;
        case 'fr':
          setlinkarr(LinkData.data.fr_arr);
          break;
        case 'nb':
          setlinkarr(LinkData.data.nb_arr);
          break;
        case 'nl':
          setlinkarr(LinkData.data.nl_arr);
          break;
        case 'pl':
          setlinkarr(LinkData.data.pl_arr);
          break;
        case 'pt':
          setlinkarr(LinkData.data.pt_arr);
          break;
        case 'sv':
          setlinkarr(LinkData.data.sv_arr);
          break;
        case 'tr':
          setlinkarr(LinkData.data.tr_arr);
          break;

        default:
          setlinkarr(LinkData.data.en_arr);
          break;
      }
      if (val[0] == 'en' && val[1] == 'us') {
        setlinkarr(LinkData.data.en_us_arr);
      }
      if (val[0] == 'en' && val[1] == 'ca') {
        setlinkarr(LinkData.data.en_us_arr);
      }
    }
  }
  const translationsResults = {
    'da-dk': 'Resultater',
    'de-at': 'Ergebnisse',
    'de-ch': 'Ergebnisse',
    'de-de': 'Ergebnisse',
    en: 'Results',
    'en-ca': 'Results',
    'en-gb': 'Results',
    'en-ie': 'Results',
    'en-us': 'Results',
    es: 'Resultados',
    'es-es': 'Resultados',
    'es-us': 'Resultados',
    'fi-fi': 'Tulokset',
    'fr-be': 'Résultats',
    'fr-ca': 'Résultats',
    'fr-fr': 'Résultats',
    'fr-ch': 'Résultats',
    'fr-lu': 'Résultats',
    'it-ch': 'Risultati',
    'it-it': 'Risultati',
    'nb-no': 'Resultater',
    'nl-nl': 'Resultaten',
    'nl-be': 'Resultaten',
    'pl-pl': 'Wyniki',
    'pt-br': 'Resultados',
    'pt-pt': 'Resultados',
    'sv-se': 'Resultat',
    'en-at': 'Results',
  };

  useEffect(() => {
    decidethepaths();

    const cachedData = localStorage.getItem(para.count + '-cachedDataSystemFinderCards');
    if (cachedData) {
      let now = new Date();
      setsystemarr(JSON.parse(cachedData).json);
      if (now.getTime() > JSON.parse(cachedData).expiry) {
        localStorage.removeItem(para.count + '-cachedDataSystemFinderCards');
      }
    } else {
      let now = new Date();

      fetch(
        'https://strapi-qrailing.azurewebsites.net/api/systems?locale=' +
          arrla1 +
          '&populate=CardImage3x2&sort[0]=Priority:desc&pagination[pageSize]=50',
        requestOptions,
      )
        .then((res) => res.json())
        .then((json) => {
          setsystemarr(json);
          localStorage.setItem(
            para.count + '-cachedDataSystemFinderCards',
            JSON.stringify({ json, expiry: now.getTime() + 24 * 60 * 60 * 1000 }),
          );
        })
        .then(() => reloadgrid());
    }

    fetch(
      'https://strapi-qrailing.azurewebsites.net/api/components?locale=' +
        arrla1 +
        '&filters[Slug][$eq]=SystemfinderSlug',
      requestOptions,
    )
      .then((res) => res.json())
      .then((json) => {
        let lal = 'FreeData1';
        setPageTranslations(json);
        setCurrentOptions(json.data[0].attributes[lal].Art);
        setFlowchartData(json.data[0].attributes[lal]);
        const queryString = window.location.search.replace('?', '');
        setimagesrc(json.data[0].attributes.FreeData3);

        let variableItem = 'Pfostengeländer';
        let level2 = 'Edelstahl';
        if (props.slug == 'handrail' || queryString == 'h' || window.location.href.includes('?h')) {
          variableItem = json.data[0].attributes.FreeData2.Handlauf;
          //eslint-disable-next-line
          //setCurrentOptions(json.data[0].attributes.FreeData1.Art[variableItem]);
          //eslint-disable-next-line
          //setFlowchartData({ Art: json.data[0].attributes.FreeData1.Art[variableItem] });
          setLevelCount(0);
          setTimeout(() => {
            let elementname = json.data[0].attributes.FreeData2.Handlauf.replace(/ /g, '-');
            elementname = elementname + 'Qrailing';
            document.getElementById(elementname).click();
          });
        }

        if (props.slug == 'FilterStainlessSteelPosts' || queryString == 'p' || window.location.href.includes('?p')) {
          variableItem = json.data[0].attributes.FreeData2.Pfostengeländer;
          level2 = json.data[0].attributes.FreeData2.Edelstahl;
          //eslint-disable-next-line
          //setCurrentOptions(json.data[0].attributes[lal].Art[variableItem][level2]);
          //eslint-disable-next-line
          //setFlowchartData({ Art: json.data[0].attributes[lal].Art[variableItem][level2] });
          setLevelCount(0);
          setTimeout(() => {
            let elementname = json.data[0].attributes.FreeData2.Pfostengeländer.replace(/ /g, '-');
            elementname = elementname + 'Qrailing';
            document.getElementById(elementname).click();
          });
        }
        if (props.slug == 'glassrailing' || queryString == 'g' || window.location.href.includes('?g')) {
          variableItem = json.data[0].attributes.FreeData2.Ganzglas;
          level2 = json.data[0].attributes.FreeData2.Ganzglas;
          //eslint-disable-next-line
          //setCurrentOptions(json.data[0].attributes.FreeData1.Art[variableItem]);
          //eslint-disable-next-line
          //setFlowchartData({ Art: json.data[0].attributes.FreeData1.Art[variableItem] });
          setLevelCount(0);
          setTimeout(() => {
            let elementname = json.data[0].attributes.FreeData2.Ganzglas.replace(/ /g, '-');
            elementname = elementname + 'Qrailing';
            document.getElementById(elementname).click();
          });
        }
        if (props.slug == 'frenchbalcony' || queryString == 'f' || window.location.href.includes('?f')) {
          variableItem = json.data[0].attributes.FreeData2['Franz. Balkon'];

          level2 = json.data[0].attributes.FreeData2['Franz. Balkon'];
          //eslint-disable-next-line
          //setCurrentOptions(json.data[0].attributes.FreeData1.Art[variableItem]);
          setLevelCount(0);
          //eslint-disable-next-line
          //setFlowchartData({ Art: json.data[0].attributes.FreeData1.Art[variableItem] });
          setTimeout(() => {
            let elementname = json.data[0].attributes.FreeData2['Franz. Balkon'].replace(/ /g, '-');
            elementname = elementname + 'Qrailing';
            document.getElementById(elementname).click();
          });
        }
      });
  }, []);

  function reloadgrid() {
    if (filteredSystemArr.length > 0) {
      return (
        <>
          {filteredSystemArr.map((item, index) => (
            <div key={'KEY' + index} className={`systemgrid-card ${animate ? 'animateCard' : ''}`}>
              <Link
                hrefLang={param.count}
                className="Systemfinder-MoreDetailsBtn"
                to={'/' + para.count + linkarr[8] + item.attributes.Slug + '/'}
              >
                <img alt="System-Image" src={item.attributes.CardImage3x2.data.attributes.url} width="164" />
              </Link>
              <div className="container-text-center">
                <div>
                  <Link
                    hrefLang={param.count}
                    className="Systemfinder-Headline"
                    to={'/' + para.count + linkarr[8] + item.attributes.Slug + '/'}
                  >
                    <h3>{item.attributes.Title}</h3>
                  </Link>
                  <p className="systemsliderSystemtext">
                    {item.attributes.HeaderText && item.attributes.HeaderText.data[1]}
                  </p>
                </div>
                <div className="Systemfinder-Links">
                  <div className="Systemfinder-Links-inner">
                    <Link
                      hrefLang={param.count}
                      className="Systemfinder-MoreDetailsBtn"
                      to={'/' + para.count + linkarr[8] + item.attributes.Slug + '/'}
                    >
                      {PageTranslations.data.length > 0
                        ? PageTranslations.data[0].attributes.PageTranslations.translations[4]
                        : 'More Details'}
                    </Link>
                    {item.attributes.PageTranslations && (
                      <>
                        {item.attributes.PageTranslations.data.length > 1 && (
                          <a className="Systemfinder-configbtn" href={item.attributes.PageTranslations.data[1][1]}>
                            {item.attributes.PageTranslations.data[1][0]}{' '}
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="32"
                                height="32"
                                fill="#000000"
                                viewBox="0 0 256 256"
                              >
                                <path d="M200,88V192a8,8,0,0,1-8,8H88a8,8,0,0,1,0-16h84.69L58.34,69.66A8,8,0,0,1,69.66,58.34L184,172.69V88a8,8,0,0,1,16,0Z"></path>
                              </svg>
                            </span>
                          </a>
                        )}
                        <a href={item.attributes.PageTranslations.data[0][1]}>
                          {item.attributes.PageTranslations.data[0][0]}{' '}
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="32"
                              height="32"
                              fill="#000000"
                              viewBox="0 0 256 256"
                            >
                              <path d="M200,88V192a8,8,0,0,1-8,8H88a8,8,0,0,1,0-16h84.69L58.34,69.66A8,8,0,0,1,69.66,58.34L184,172.69V88a8,8,0,0,1,16,0Z"></path>
                            </svg>
                          </span>
                        </a>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      );
    }

    return (
      <>
        {systemarr.data.map((item, index) => (
          <div key={'KEY' + index} className={`systemgrid-card ${animate ? 'animateCard' : ''}`}>
            <Link
              hrefLang={param.count}
              className="Systemfinder-MoreDetailsBtn"
              to={'/' + para.count + linkarr[8] + item.attributes.Slug + '/'}
            >
              <img alt="System-Image" src={item.attributes.CardImage3x2.data.attributes.url} width="164" />
            </Link>
            <div className="container-text-center">
              <div>
                <Link
                  hrefLang={param.count}
                  className="Systemfinder-Headline"
                  to={'/' + para.count + linkarr[8] + item.attributes.Slug + '/'}
                >
                  <h3>{item.attributes.Title}</h3>
                </Link>
                <p className="systemsliderSystemtext">
                  {item.attributes.HeaderText && item.attributes.HeaderText.data[1]}
                </p>
              </div>
              <div className="Systemfinder-Links">
                <div className="Systemfinder-Links-inner">
                  <Link
                    id={'Systemfinder-MoreDetailsBtn-' + item.attributes.Slug}
                    hrefLang={param.count}
                    className="Systemfinder-MoreDetailsBtn"
                    to={'/' + para.count + linkarr[8] + item.attributes.Slug + '/'}
                  >
                    {PageTranslations.data.length > 0
                      ? PageTranslations.data[0].attributes.PageTranslations.translations[4]
                      : 'More Details'}
                  </Link>
                  {item.attributes.PageTranslations && (
                    <>
                      {item.attributes.PageTranslations.data.length > 1 && (
                        <a
                          className="Systemfinder-configbtn"
                          id={'Systemfinder-ConfigureBtn-' + item.attributes.Slug}
                          href={item.attributes.PageTranslations.data[1][1]}
                        >
                          {item.attributes.PageTranslations.data[1][0]}
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="32"
                              height="32"
                              fill="#000000"
                              viewBox="0 0 256 256"
                            >
                              <path d="M200,88V192a8,8,0,0,1-8,8H88a8,8,0,0,1,0-16h84.69L58.34,69.66A8,8,0,0,1,69.66,58.34L184,172.69V88a8,8,0,0,1,16,0Z"></path>
                            </svg>
                          </span>
                        </a>
                      )}
                      <a
                        href={item.attributes.PageTranslations.data[0][1]}
                        id={'Systemfinder-BuyNowBtn-' + item.attributes.Slug}
                      >
                        {item.attributes.PageTranslations.data[0][0]}{' '}
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            fill="#000000"
                            viewBox="0 0 256 256"
                          >
                            <path d="M200,88V192a8,8,0,0,1-8,8H88a8,8,0,0,1,0-16h84.69L58.34,69.66A8,8,0,0,1,69.66,58.34L184,172.69V88a8,8,0,0,1,16,0Z"></path>
                          </svg>
                        </span>
                      </a>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </>
    );
  }

  const questions = {
    Art: 'What type of railing are you interested in?',
    Ganzglas: 'What type of glass railing do you prefer?',
    Bodenprofil: 'What load capacity do you need?',
    Bodenklemmen: 'Do you need fall protection?',
    Adapter: 'What type of adapter do you need?',
    Punkthalter: 'What shape of point holder do you prefer?',
    Blockadapter: 'What shape of block adapter do you prefer?',
    Pfostengeländer: 'What material do you prefer?',
    Edelstahl: 'What type of stainless steel filling do you prefer?',
    Aluminium: 'What type of aluminum filling do you prefer?',
    'Franz. Balkon': 'What type of French balcony do you need?',
    'Bodentiefes Fenster': 'What type of floor-to-ceiling window do you need?',
    'Nicht Bodentief': 'What type of non-floor-to-ceiling window do you need?',
    Handlauf: 'What type of handrail do you prefer?',
    Holz: 'What type of wooden handrail do you prefer?',
    Gebogen: 'Do you need a curved wooden handrail?',
    Gerade: 'Do you need a straight wooden handrail?',
    Rund: 'Do you need a round handrail?',
    Eckig: 'Do you need a square handrail?',
  };

  const renderOptions = (options) => {
    if (Array.isArray(options)) {
      const accumulator = options.map((option) => option.toLowerCase()); // Adjust based on your actual options structure

      // looks like its gonna get used in the future, please remove if not. @Q-railing-Rene
      // eslint-disable-next-line no-unused-vars
      const filteredSystemArr2 = systemarr.data.filter((item) =>
        accumulator.includes(item.attributes.Slug.toLowerCase()),
      );

      return (
        <div className="cards hidden">
          {options.map((option, index) => (
            <div key={index} className="card">
              {option}
            </div>
          ))}
        </div>
      );
    }

    return (
      <div className="cards">
        {Object.keys(options).map((option, index) => {
          let img = imagesrc ? imagesrc[option] : imagesData[option];

          let choices = optionstring.split('|');
          let theselect = choices[choices.length - 2];

          if (imagesrc) {
            if (imagesrc[theselect + '/' + option]) {
              img = imagesrc[theselect + '/' + option];
            }
          }

          return (
            <div
              key={index}
              data={option}
              className={'card '}
              onClick={() => {
                handleCardClick(option);
              }}
              id={(breadcrumbs.replace('/', '') + option + 'Qrailing').replace(
                /[/",./()[\]{}+*#'!§$%&=?\\|<>^° ]/g,
                '-',
              )}
              onMouseEnter={() => {
                /*document.getElementById('flowchart-background').style =
                'background-image:url(' +
                imagesData[option] +
                ');background-size:cover;background-position:center;padding-right:220px';*/
              }}
            >
              {<img src={img} className="SystemFinderOptionImage" alt={option} width="50" height="50" />}
              <p className={option && option.length > 20 && 'SystemFinderFindLongWord'}>{option.replace(/_/g, ' ')}</p>
            </div>
          );
        })}
      </div>
    );
  };
  let [data, setdata] = useState([]);
  const [path, setPath] = useState([]);
  const [currentOptions, setCurrentOptions] = useState(flowchartData.Art);
  const [subOptions, setSubOptions] = useState(null);

  const handleCardClick = (option) => {
    const newPath = [...path, option];

    setLevelCount(levelCount + 1);
    setOptionString(optionstring + option + '|');

    setPath(newPath);
    const newOptions = currentOptions[option];
    let strinop = newPath + '';
    strinop = strinop.replace(/,/g, '');
    setCurrentOptions(newOptions);
    setcurrentLevel(option);
    setbreadcrumbs(breadcrumbs + '/' + option);

    setOptions(newOptions);
    setSubOptions(newOptions);
  };

  const handleBackClick = () => {
    const newPath = path.slice(0, -1);
    let strinop = newPath + '';
    strinop = strinop.replace(/,/g, '');
    setPath(newPath);

    let optionstringadjust = optionstring.slice(0, optionstring.lastIndexOf('|'));
    optionstringadjust = optionstringadjust.slice(0, optionstringadjust.lastIndexOf('|') + 1);
    setOptionString(optionstringadjust);

    if (levelCount != 0) {
      setLevelCount(levelCount - 1);
    }

    let newOptions = flowchartData.Art;
    newPath.forEach((option) => {
      newOptions = newOptions[option];
    });
    const lastSlashIndex = breadcrumbs.lastIndexOf('/');
    const newBreadcrumbs = lastSlashIndex !== -1 ? breadcrumbs.slice(0, lastSlashIndex) : '';
    setbreadcrumbs(newBreadcrumbs);
    setOptions(newOptions);
    setcurrentLevel(newOptions);
    setCurrentOptions(newOptions);
    setSubOptions(newOptions);
  };

  const [options, setOptions] = useState();

  useEffect(() => {
    const renderSubOptions = (options) => {
      const maxDepth = 4;
      let queue = [{ options: options, depth: 0 }];
      let accumulator = [];

      while (queue.length > 0) {
        const { options, depth } = queue.shift(); // Get the first item from the queue

        if (options && depth <= maxDepth) {
          Object.entries(options).forEach(([, value]) => {
            if (Array.isArray(value)) {
              accumulator.push(...value);
            } else if (value && typeof value === 'object') {
              queue.push({ options: value, depth: depth + 1 });
            }
          });
        }
      }

      // After processing all levels, filter systemarr based on accumulator slugs
      const filteredSystemArr2 = systemarr.data.filter((item) => accumulator.includes(item.attributes.Slug));
      setdata(filteredSystemArr2);
    };

    renderSubOptions(options);
  }, [options]);

  const renderSubOptions = (initialOptions) => {
    const maxDepth = 4;
    let queue = [{ options: initialOptions, depth: 0 }];
    let accumulator = [];

    while (queue.length > 0) {
      const { options, depth } = queue.shift(); // Get the first item from the queue

      // Instead of using continue, invert the condition to only proceed when valid
      if (options && depth <= maxDepth) {
        Object.entries(options).forEach(([, value]) => {
          if (Array.isArray(value)) {
            // Add the array's values to the accumulator
            accumulator.push(...value);
          } else if (value && typeof value === 'object') {
            // If the value is an object (and not an array), add it to the queue for further processing
            queue.push({ options: value, depth: depth + 1 });
          }
        });
      }
    }

    // After processing all levels, filter systemarr based on accumulator slugs
    // also looks like its gonna be used in the fututre, please remove if not. @Q-railing-Rene
    // eslint-disable-next-line no-unused-vars
    const filteredSystemArr2 = systemarr.data.filter((item) => accumulator.includes(item.attributes.Slug));
  };

  useEffect(() => {
    if (Array.isArray(currentOptions)) {
      let options = currentOptions;
      const accumulator = options.map((option) => option.toLowerCase()); // Adjust based on your actual options structure
      const filteredSystemArr2 = systemarr.data.filter((item) =>
        accumulator.includes(item.attributes.Slug.toLowerCase()),
      );
      setdata(filteredSystemArr2);
    }
  }, [currentOptions]);

  const [animate, setAnimate] = useState(false);
  useEffect(() => {
    setAnimate(true);
    const timeout = setTimeout(() => setAnimate(false), 1000);
    return () => clearTimeout(timeout);
  }, [systemarr, currentOptions]);
  const breakpoints = {
    // When window width is >= 768px
    1024: {
      slidesPerView: 3,
    },
    768: {
      slidesPerView: 2,
    },
    // When window width is >= 576px
    576: {
      slidesPerView: 2,
    },
    // When window width is >= 320px
    320: {
      slidesPerView: 1,
    },
    100: {
      slidesPerView: 1,
    },
  };

  return (
    <>
      <div className="SystemFinder-wrap-head">
        <div className="SystemFinder-innerHead">
          <h2>
            {PageTranslations.data.length > 0
              ? PageTranslations.data[0].attributes.PageTranslations.translations[0]
              : 'Das passende System für jedes Projekt'}
          </h2>
        </div>
      </div>
      {/*<h2>{questions[currentLevel]}</h2>*/}
      <div className="SystemFinder-wrap">
        <div className="SystemFinder-inner">
          <div className="systemgrid-flowchart-wrap">
            <div className="flowchart" id="flowchart-background">
              <div className="flowchart-BackBtnDiv">
                {path.length > 0 && (
                  <button id="Systemfinder-backbtnClick" onClick={handleBackClick}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M21.0006 11.9999C21.0006 12.1988 20.9216 12.3896 20.7809 12.5303C20.6403 12.6709 20.4495 12.7499 20.2506 12.7499H5.5609L11.0312 18.2193C11.1009 18.289 11.1562 18.3717 11.1939 18.4628C11.2316 18.5538 11.251 18.6514 11.251 18.7499C11.251 18.8485 11.2316 18.9461 11.1939 19.0371C11.1562 19.1281 11.1009 19.2109 11.0312 19.2806C10.9615 19.3502 10.8788 19.4055 10.7878 19.4432C10.6967 19.4809 10.5991 19.5003 10.5006 19.5003C10.402 19.5003 10.3045 19.4809 10.2134 19.4432C10.1224 19.4055 10.0396 19.3502 9.96996 19.2806L3.21996 12.5306C3.15023 12.4609 3.09491 12.3782 3.05717 12.2871C3.01943 12.1961 3 12.0985 3 11.9999C3 11.9014 3.01943 11.8038 3.05717 11.7127C3.09491 11.6217 3.15023 11.539 3.21996 11.4693L9.96996 4.7193C10.1107 4.57857 10.3016 4.49951 10.5006 4.49951C10.6996 4.49951 10.8905 4.57857 11.0312 4.7193C11.1719 4.86003 11.251 5.05091 11.251 5.24993C11.251 5.44895 11.1719 5.63982 11.0312 5.78055L5.5609 11.2499H20.2506C20.4495 11.2499 20.6403 11.3289 20.7809 11.4696C20.9216 11.6103 21.0006 11.801 21.0006 11.9999Z"
                        fill="#fff"
                      />
                    </svg>
                  </button>
                )}
              </div>
              {renderOptions(currentOptions)}
              {renderSubOptions(subOptions)}
            </div>
          </div>

          <div className="SystemFinder-Breadcrumbs-wrap">
            <h2>{translationsResults[param.count]}</h2>
            <div className="SystemFinder-Breadcrumbs-inner">
              {breadcrumbs.length > 0 && (
                <p className="SystemFinder-Breadcrumb-wrap">
                  {breadcrumbs.split('/').map((crumb, index) => (
                    <>
                      {index > 0 && (
                        <div key={index} onClick={() => handleBackClick(index)}>
                          <button
                            className="SystemFinder-BreadcrumbButton"
                            id={levelCount == index && 'activeSysFinderBtn'}
                            key={index}
                            onClick={() => handleBackClick(index)}
                          >
                            {crumb}

                            {levelCount == index && (
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="32"
                                  height="32"
                                  fill="#B4B4B4"
                                  viewBox="0 0 256 256"
                                >
                                  <path d="M165.66,101.66,139.31,128l26.35,26.34a8,8,0,0,1-11.32,11.32L128,139.31l-26.34,26.35a8,8,0,0,1-11.32-11.32L116.69,128,90.34,101.66a8,8,0,0,1,11.32-11.32L128,116.69l26.34-26.35a8,8,0,0,1,11.32,11.32ZM232,128A104,104,0,1,1,128,24,104.11,104.11,0,0,1,232,128Zm-16,0a88,88,0,1,0-88,88A88.1,88.1,0,0,0,216,128Z"></path>
                                </svg>
                              </span>
                            )}
                          </button>
                        </div>
                      )}
                    </>
                  ))}
                </p>
              )}
            </div>
          </div>

          <div className="systemfinder-grid">
            {data.length == 0 ? (
              <>{reloadgrid()}</>
            ) : (
              <>
                {data.map((item, index) => {
                  return (
                    <div key={'KEY' + index} className={`systemgrid-card ${animate ? 'animateCard' : ''}`}>
                      <Link
                        hrefLang={param.count}
                        className="Systemfinder-MoreDetailsBtn"
                        to={'/' + para.count + linkarr[8] + item.attributes.Slug + '/'}
                      >
                        <img alt="System-Image" src={item.attributes.CardImage3x2.data.attributes.url} width="164" />
                      </Link>
                      <div className="container-text-center">
                        <div>
                          <Link
                            hrefLang={param.count}
                            className="Systemfinder-Headline"
                            to={'/' + para.count + linkarr[8] + item.attributes.Slug + '/'}
                          >
                            <h3>{item.attributes.Title}</h3>
                          </Link>
                          <p className="systemsliderSystemtext">
                            {item.attributes.HeaderText && item.attributes.HeaderText.data[1]}
                          </p>
                        </div>
                        <div className="Systemfinder-Links">
                          <div className="Systemfinder-Links-inner">
                            <Link
                              hrefLang={param.count}
                              className="Systemfinder-MoreDetailsBtn"
                              to={'/' + para.count + linkarr[8] + item.attributes.Slug + '/'}
                            >
                              {PageTranslations.data.length > 0
                                ? PageTranslations.data[0].attributes.PageTranslations.translations[4]
                                : 'More Details'}{' '}
                            </Link>
                            {item.attributes.PageTranslations && (
                              <>
                                {item.attributes.PageTranslations.data.length > 1 && (
                                  <a
                                    className="Systemfinder-configbtn"
                                    href={item.attributes.PageTranslations.data[1][1]}
                                  >
                                    {item.attributes.PageTranslations.data[1][0]}{' '}
                                    <span>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="32"
                                        height="32"
                                        fill="#000000"
                                        viewBox="0 0 256 256"
                                      >
                                        <path d="M200,88V192a8,8,0,0,1-8,8H88a8,8,0,0,1,0-16h84.69L58.34,69.66A8,8,0,0,1,69.66,58.34L184,172.69V88a8,8,0,0,1,16,0Z"></path>
                                      </svg>
                                    </span>
                                  </a>
                                )}
                                <a href={item.attributes.PageTranslations.data[0][1]}>
                                  {item.attributes.PageTranslations.data[0][0]}{' '}
                                  <span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="32"
                                      height="32"
                                      fill="#000000"
                                      viewBox="0 0 256 256"
                                    >
                                      <path d="M200,88V192a8,8,0,0,1-8,8H88a8,8,0,0,1,0-16h84.69L58.34,69.66A8,8,0,0,1,69.66,58.34L184,172.69V88a8,8,0,0,1,16,0Z"></path>
                                    </svg>
                                  </span>
                                </a>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
export default SystemFinder;
