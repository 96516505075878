import Footernew from 'components/includes/Footernew';
import HeaderSlim from 'components/includes/HeaderSlim';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import './ProfileAddressBook.css';

function ProfileAddressBook(props) {
  let para = useParams();
  let login = localStorage.getItem('access_token');
  let login2 = localStorage.getItem('id_token');
  const [AddressData, setAddressData] = useState({});

  if (!login) {
    window.location.href = '/de-de/';
  }
  useEffect(() => {
    let impersonate_id = localStorage.getItem('impersonate_id');
    if (!impersonate_id) {
      fetch('http://localhost:3004/auth/useraddressesreturn', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${login2}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          console.log(data);
          setAddressData(data);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    } else {
      fetch('http://localhost:3004/auth/impersonateuseraddresses', {
        method: 'Post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ access_token: login2, accountId: impersonate_id }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          console.log(data);
          setAddressData(data);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  }, []);

  const hasKeys = AddressData && Object.keys(AddressData).length > 0;
  return (
    <>
      <div className="ProfileAddressBook-wrap">
        <div className="ProfileAddressBook-inner">
          <div className="ProfileAddressBook-Headline">
            <h2>Your shop addresses</h2>
            <br />
            <p>You can find your ERP addresses here</p>
            <br />
            <br />
          </div>

          {hasKeys && (
            <>
              <div className="ProfileAddressBook-Addresses">
                <div className="ProfileAddressBook-BillingAddress">
                  <h3>Billing_addresses</h3>
                  {AddressData.billing_addresses.map((item, index) => {
                    console.log(item);

                    return (
                      <div className="ProfileAddressBook-Address">
                        <span>
                          <p>Name:</p>
                          <p>{item.name}</p>
                        </span>
                        <span>
                          <p>Street+Number:</p>
                          <p>{item.street_number}</p>
                        </span>
                        <span>
                          <p>Postcode:</p>
                          <p>{item.postcode}</p>
                        </span>
                        <span>
                          <p>City:</p>
                          <p>{item.city}</p>
                        </span>
                        <span>
                          <p>Country:</p>
                          <p>{item.country}</p>
                        </span>
                      </div>
                    );
                  })}
                </div>

                <div className="ProfileAddressBook-Shippingaddress">
                  <h3>shipping_addresses</h3>
                  {AddressData.shipping_addresses.map((item, index) => {
                    return (
                      <div className="ProfileAddressBook-Address">
                        <span>
                          <p>Name:</p>
                          <p>{item.name}</p>
                        </span>
                        <span>
                          <p>Street+Number:</p>
                          <p>{item.street_number}</p>
                        </span>
                        <span>
                          <p>Postcode:</p>
                          <p>{item.postcode}</p>
                        </span>
                        <span>
                          <p>City:</p>
                          <p>{item.city}</p>
                        </span>
                        <span>
                          <p>Country:</p>
                          <p>{item.country}</p>
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default ProfileAddressBook;
