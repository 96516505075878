import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import './AdminCustomerOrders.css';

function AdminCustomerOrders(props) {
  let login = localStorage.getItem('access_token');
  let login2 = localStorage.getItem('id_token');
  let [datafetched, setdatafetched] = useState();
  let [shopdata, setshopdata] = useState();
  let id = props.shopid;
  if (!id) {
    id = 'user_accounts';
  }

  let para = useParams();

  useEffect(() => {
    fetch('http://localhost:3004/auth/ordersadmin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ access_token: login2, accountId: props.shopid }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setdatafetched(data);
        console.log(data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);

  return (
    <>
      <div className="AdminCustomerOrders-wrap">
        <div className="AdminCustomerAddresses-inner">
          {datafetched && (
            <>
              {datafetched.length > 0 && (
                <div className="AdminCustomerOrders-Orders">
                  <div className="AdminCustomerAddresses-Addresses">
                    <div className="AdminCustomerOrders-Orders">
                      <h3>Orders</h3>
                      <br />
                      <table className="AdminCustomerOrders-Orderlist">
                        <tr className="AdminCustomerOrders-Tablehead">
                          <td>
                            <span>Order_Id</span>
                          </td>
                          <td>
                            <span>Status</span>
                          </td>
                          <td>
                            <span>order_amount</span>
                          </td>
                          <td>
                            <span>Discount code</span>
                          </td>
                          <td>
                            <span>Discount amount</span>
                          </td>

                          <td>
                            <span>Shipping_id</span>
                          </td>

                          <td>
                            <span>Order_reference</span>
                          </td>
                          <td>
                            <span>Tax_amount</span>
                          </td>
                        </tr>
                        {datafetched.map((item, index) => {
                          return (
                            <tr key={index} className="AdminCustomerOrders-InnerTable">
                              <td>
                                <Link to={'/de-de/admin/users/' + props.urlid + '/' + item.order_id}>
                                  <span>{item.order_id}</span>
                                </Link>
                              </td>
                              <td>
                                <span>{item.status}</span>
                              </td>
                              <td>
                                <span>{item.total_price}</span>
                              </td>
                              <td>
                                <span>{item.discount_code}</span>
                              </td>
                              <td>
                                <span>{item.discount_amount}</span>
                              </td>

                              <td>
                                <span>{item.shipping_address_id}</span>
                              </td>

                              <td>
                                <span>{item.order_reference}</span>
                              </td>

                              <td>
                                <span>{item.tax_amount}</span>
                              </td>
                            </tr>
                          );
                        })}
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default AdminCustomerOrders;
